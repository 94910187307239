'use client';
import { useEffect } from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function Error({
    error,
    reset
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        // Log the error to an error reporting service
        console.error('[Error catched]', error);
    }, [error]);

    return (
        <div className="w-full flex flex-col items-center gap-8 mt-32">
            <ErrorOutlineOutlinedIcon className="text-[3em] text-red" />
            <h2 className="text-xl">Oups! une erreur est survénue</h2>
            <button
                className="border border-gray px-8 py-2"
                onClick={
                    // Attempt to recover by trying to re-render the segment
                    () => reset()
                }>
                Réessayer
            </button>
        </div>
    );
}
